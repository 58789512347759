import React, { useEffect } from 'react';
import AOS from 'aos';
import Image from 'next/legacy/image';
import { Box } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import styles from './styles';

interface TokenCardsProps {
  title: string;
  img: string;
  content?: string;
  alt: string;
}

export default function TokenCards({
  title,
  img,
  content,
  alt
}: TokenCardsProps) {
  useEffect(() => {
    AOS.init({ duration: 2000 });
  }, []);
  return (
    <Card sx={styles.root} data-aos="fade-up">
      <Box sx={styles.imageContainer}>
        <CardMedia title={title}>
          <Image
            src={img}
            height={300}
            width={300}
            objectFit={'contain'}
            alt={alt}
          />
        </CardMedia>
      </Box>
      <CardContent>
        <Typography sx={styles.cardTitle} variant="h5" component="h3">
          {title}
        </Typography>
        {content && (
          <Typography variant="body2" color="textSecondary" component="p">
            {content}
          </Typography>
        )}
      </CardContent>
    </Card>
  );
}
