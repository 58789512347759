import React, { useEffect } from 'react';
import AOS from 'aos';
import { Card } from '@mui/material';
import 'aos/dist/aos.css';

const RoadMapYears = () => {
  useEffect(() => {
    AOS.init({ duration: 2000 });
  }, []);

  return (
    <div className="roadMap__body">
      <div className="roadMap__bodyYear--one" data-aos="fade-up">
        <div className="roadMap__bodyYear__hexagono--2023">
          <div className="roadMap__bodyYear__hexagono__text">
            2023 <br />
            <p>& foward</p>{' '}
          </div>
        </div>
        <div className="roadMap__text--left">
          <p>
            DEFI integration to crypto solution <br /> White-label solution{' '}
            <br /> Real world asset tokenization <br /> Growth phase
          </p>
        </div>
      </div>
      <div className="roadMap__arrowRight "></div>
      <div className="roadMap__bodyColor--c1"></div>
      <div className="roadMap__bodyColor--c2"></div>
      <div className="roadMap__arrowLeft--arr2"></div>
      <div className="roadMap__bodyYear--two" data-aos="fade-up">
        <div className="roadMap__bodyYear__hexagono--2022-2">
          <div className="roadMap__bodyYear__hexagono__text">
            2022 <br />
            <p>Q3/Q4</p>{' '}
          </div>
        </div>
        <div className="roadMap__text--right">
          <p>
            Aggreated solution for wallet <br /> Smat token public sale <br />
            Token listing on exchanges
            <br /> 50+ clients in CH & FR
          </p>
        </div>
      </div>
      <div className="roadMap__bodyYear--tree" data-aos="fade-up">
        <div className="roadMap__bodyYear__hexagono--2022-1">
          <div className="roadMap__bodyYear__hexagono__text">
            2022 <br />
            <p>Q2/Q1</p>{' '}
          </div>
        </div>
        <div className="roadMap__text--left">
          <p>
            FINMA Approval <br /> Tokenomics & Token Presale <br /> WM Wallet
            solution design
          </p>
        </div>
      </div>
      <div className="roadMap__arrowRight--arr3 "></div>
      <div className="roadMap__bodyColor--c3"></div>
      <div className="roadMap__bodyColor--c4"></div>
      <div className="roadMap__arrowLeft--arr4"></div>
      <div className="roadMap__bodyYear--four" data-aos="fade-up">
        <div className="roadMap__bodyYear__hexagono--2021">
          <div className="roadMap__bodyYear__hexagono__text">
            2021 <br />
          </div>
        </div>
        <div className="roadMap__text--right">
          <p>
            Web 2 MVP Launch <br /> Onboarding WM
          </p>
        </div>
      </div>
      <div className="roadMap__bodyYear--five" data-aos="fade-up">
        <div className="roadMap__bodyYear__hexagono--2020">
          <div className="roadMap__bodyYear__hexagono__text--hex2020">
            2020 <br />
          </div>
        </div>
        <div className="roadMap__text--left">
          <p>
            Team & App Development <br /> Partnerships kick-off{' '}
          </p>
        </div>
      </div>
      <div className="roadMap__arrowRight--arr5 "></div>
      <div className="roadMap__bodyColor--c5"></div>
      <div className="roadMap__bodyColor--c6"></div>
      <div className="roadMap__arrowLeft--arr6"></div>
      <div className="roadMap__bodyYear--six" data-aos="fade-up">
        <div className="roadMap__bodyYear__hexagono--2019">
          <div className="roadMap__bodyYear__hexagono__text--hex2019">
            2019 <br />
            <p>Q4</p>{' '}
          </div>
        </div>
        <div className="roadMap__text--right">
          <p>SMAT S.A. creation</p>
        </div>
      </div>
    </div>
  );
};

const RoadMap = () => {
  return (
    <Card
      className="roadMap__container"
      style={{ backgroundColor: 'white', padding: '2rem' }}
    >
      <RoadMapYears />
    </Card>
  );
};

export default RoadMap;
