import { useState } from 'react';
import { GetStaticProps } from 'next';
import { useTranslation } from 'next-i18next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import Image from 'next/legacy/image';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { CLIENT_FEATURES } from '@/common/config/features';
import { SEO } from '@/components';
import TokenCards from '@/components/Card/Card';
import RoadMap from '@/components/RoadMap';
import HomeLayout from '@/layouts/home.layout';
import { getAssets } from '@/services/minio.service';
import {
  Box,
  Container,
  Typography,
  useMediaQuery,
  Button
} from '@mui/material';
import { useTheme } from '@mui/system';
import styles from '../assets/styles/tokenStyles';

export const getStaticProps: GetStaticProps = async ({ locale }) => {
  return {
    props: {
      // @ts-expect-error wontfix
      ...(await serverSideTranslations(locale, [
        'landing',
        'common',
        'footer',
        'nav'
      ])),
      locale,
      reloadOnPrerender: process.env.NODE_ENV !== 'production'
    }
  };
};

function HomePage() {
  const [isHeroLoaded, setIsHeroLoaded] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const { isReady } = useRouter();
  // const { user, error, isLoading } = useUser();

  const { t } = useTranslation();
  if (!isReady) return null;
  return (
    <>
      <SEO />
      <HomeLayout>
        <Box
          component={'section'}
          sx={{ ...styles.sectionOne, ...styles.tokenSection }}
          id="token-connect"
          style={{ paddingTop: '1.5rem' }}
        >
          <Container maxWidth="xl" sx={styles.container}>
            <Box
              display={'flex'}
              flexDirection={isMobile ? 'column-reverse' : 'row'}
              mt={-3}
            >
              {!isMobile && <MainTitle />}
              <Box
                flex={1}
                display={'inline-block'}
                style={{ margin: 'auto' }}
                sx={{
                  ...styles.heroImg,
                  ...(isHeroLoaded && styles.showHeroImg)
                }}
              >
                <Image
                  onLoadingComplete={() => setIsHeroLoaded(true)}
                  priority
                  src={getAssets('v2/token-hero.webp')}
                  height={1200}
                  width={1600}
                  layout={isMobile ? 'intrinsic' : 'responsive'}
                  alt="hero"
                />
                {isMobile && <MainTitle />}

                <Box sx={styles.buyTokenContainer}>
                  {/* <Typography sx={styles.subTitle}>
                  {CLIENT_FEATURES.Dashboard
                    ? t('landing:sectionOne.itsAlive')
                    : t('landing:sectionOne.comingSoon')}
                 
                </Typography> */}
                  {CLIENT_FEATURES.Dashboard && (
                    <Button
                      color="primary"
                      variant={'contained'}
                      href={'/login'}
                      sx={{ padding: '1.5rem 4rem' }}
                    >
                      {/* {t('landing:sectionOne.buySmat')} */}
                      {t('landing:sectionOne.goDashboard')}
                    </Button>
                  )}
                </Box>
              </Box>
            </Box>
          </Container>
        </Box>
        <Box component={'section'} sx={styles.sectionTwo}>
          <Container maxWidth="xl" sx={styles.cardsContainer}>
            {getButtons(t('landing:buttons', { returnObjects: true })).map(
              ({ img, ...props }) => (
                <span key={props.title}>
                  <TokenCards img={img || ''} {...props} />
                </span>
              )
            )}
          </Container>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              paddingInline: { xs: '1rem', md: 'unset' }
            }}
          >
            <Typography
              sx={styles.moreToCome}
              align={'center'}
              variant={isMobile ? 'h4' : 'h3'}
              component={'h4'}
            >
              {t('landing:buttons.more')}
            </Typography>
          </Box>
        </Box>
        <Box
          component={'section'}
          sx={{ ...styles.tokenSection, ...styles.sectionThree }}
          id="landing-bridging"
        >
          <Container maxWidth="xl" sx={styles.container}>
            <Typography
              align={'center'}
              sx={{ ...styles.title, ...styles.bigTitle }}
              component={'h2'}
            >
              {t('landing:sectionTwo.title')}
            </Typography>
            <Typography
              gutterBottom
              align={'center'}
              sx={styles.content}
              component={'h5'}
            >
              {t('landing:sectionTwo.content')}
            </Typography>
            <Link href={'/contactus'} passHref legacyBehavior>
              <Button
                variant="contained"
                color={'primary'}
                href={'/contactus'}
                sx={{ fontSize: '2rem', padding: '2rem 4rem' }}
              >
                {t('landing:sectionTwo.contactUs')}
              </Button>
            </Link>
          </Container>
        </Box>
        <Box
          component={'section'}
          sx={styles.sectionFour}
          id="landing-tokenomics"
        >
          <Container maxWidth="xl" sx={styles.container}>
            <Typography sx={{ ...styles.title, ...styles.bigTitle }}>
              {t('landing:sectionThree.title')}
            </Typography>
            <Box sx={styles.roadMapContainer}>
              <Box sx={styles.sectionFourContent}>
                <Typography
                  align={'center'}
                  gutterBottom
                  component={'h4'}
                  sx={{
                    ...styles.subTitle,
                    border: '2px solid #292929',
                    padding: '9px 18px',
                    borderRadius: '5px'
                  }}
                >
                  {t('landing:sectionThree.subTitle')}
                </Typography>
                <Typography
                  variant={'body2'}
                  component={'p'}
                  align={'center'}
                  sx={{
                    paddingInline: '2rem',
                    fontSize: { xs: '1.25rem', md: '1.9rem' }
                  }}
                >
                  {t('landing:sectionThree.content')}
                </Typography>
              </Box>
              <Box sx={styles.roadMap}>
                <RoadMap />
              </Box>
            </Box>
          </Container>
        </Box>
      </HomeLayout>
    </>
  );
}

const MainTitle = () => {
  const { t } = useTranslation();
  return (
    <Box
      flex={1}
      sx={{ marginBottom: { xs: 5, md: 0 } }}
      display={'flex'}
      flexDirection="column"
      justifyContent={'center'}
      alignItems={'center'}
    >
      <Typography
        align={'left'}
        sx={{
          ...styles.title
        }}
        component={'h1'}
      >
        {t('landing:sectionOne.title')}
      </Typography>
      <Typography
        variant={'body2'}
        sx={{
          width: '100%',
          paddingInline: '2rem',
          fontSize: { xs: '1.25rem', md: '1.9rem' },
          textAlign: { xs: 'center', md: 'left' }
        }}
        component={'h2'}
      >
        {t('landing:sectionOne.content')}
      </Typography>
    </Box>
  );
};

const getButtons = (wording) => [
  {
    title: wording.first,
    alt: 'Wealth Managers',
    img: getAssets('clients-p.webp', {
      bucket: 'smat-homepage/images'
    })
  },
  {
    title: wording.second,
    alt: 'Manage portfolio',
    img: getAssets('portfolio-s.webp', {
      bucket: 'smat-homepage/images'
    })
  },
  {
    title: wording.third,
    alt: 'Secured assets',
    img: getAssets('security2-p.webp', {
      bucket: 'smat-homepage/images'
    })
  },
  {
    title: wording.fourth,
    alt: 'Access to digital assets',
    img: getAssets('work-together-s.webp', {
      bucket: 'smat-homepage/images'
    })
  }
];

// const getDataCard = (t: TFunction) => [
//   {
//     name: 'clients-p.webp',
//     text: t('landing:buttons.first'),
//   },
//   {
//     name: 'portfolio-s.webp',
//     text: t('landing:buttons.second'),
//   },
//   { name: 'security2-p.webp', text: t('landing:buttons.third') },
//   {
//     name: 'work-together-s.webp',
//     text: t('landing:buttons.fourth'),
//   },
// ];

HomePage.forceLightMode = true;

export default HomePage;
