import { Theme } from '@mui/material';

const styles = {
  root: {
    maxWidth: 345,
    borderRadius: '1.5rem',
    backgroundImage: ({ palette }: Theme) => palette.primary.gradient,
    zIndex: 100,
    display: 'flex',
    justifyContent: 'space-around',
    flexDirection: 'column'
  },
  image: {
    // aspectRatio: '1/1',
    objectFit: 'cover',
    margin: '1rem'
  },
  imageContainer: {
    margin: {
      md: '1rem 3rem 0rem',
      xs: '0.25rem 1.5rem 0rem'
    }
  },
  cardTitle: {
    fontFamily: 'Poppins',
    lineHeight: 1.3,
    fontWeight: 500,
    textAlign: 'center',
    fontSize: {
      xs: '1.25rem',
      md: '1.5rem'
    },
    paddingInline: '1rem'
  }
};

export default styles;
