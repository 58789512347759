import { getAssets } from '@/services/minio.service';
import { keyframes } from '@emotion/react';
import { Theme } from '@mui/material';

const getBounceAnimation = keyframes`
0%, 100% {
  transform: translateY(-10%);
  animation-timing-function: cubic-bezier(0.8, 0, 1, 1);
}
50% {
  transform: translateY(0);
  animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
}
`;

const styles = {
  container: {
    gap: '1rem',
    minHeight: { xs: 'unset', sm: '100vh' },
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  secondaryButtons: {
    display: 'flex',
    flexDirection: { xs: 'column', sm: 'row' },
    justifyContent: 'center',
    alignItems: 'center',
    gap: { xs: '1rem', sm: '2rem' },
    width: '100%'
  },
  loadingContainer: {
    width: '100vw',
    height: '100vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  cardsContainer: {
    width: '100vw',
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fit, minmax(200px, 1fr))',
    gap: {
      xs: '1rem',
      md: '3rem'
    },
    paddingInline: { xs: '1rem', md: '3rem' }
  },
  tokenSection: {
    minHeight: { xs: 'unset', md: '100vh' },
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(216, 223, 233, 0.21)',
    padding: { xs: '3rem 0.5rem', md: 'unset' },
    position: 'relative'
  },
  heroContainer: {
    minHeight: { xs: 275, sm: 400, md: '500px' },
    maxWidth: { xs: '100vw', md: '50vw' },
    minWidth: { xs: '100vw', sm: '50vw' },
    maxHeight: { xs: 275, sm: '500px' },
    marginTop: { xs: '0px', sm: 'unset' }
  },
  heroImg: {
    transition: ({ transitions }: Theme) =>
      transitions.create(['transform', 'opacity', 'filter'], {
        duration: 2000,
        easing: transitions.easing.easeInOut
      }),
    objectFit: 'cover',
    opacity: 1,
    filter: 'blur(2px)',
    transform: 'translate(0px,-20px)'
  },
  showHeroImg: {
    filter: 'unset !important',
    opacity: 1,
    transform: 'translate(0px,0px)'
    // minHeight: 'unset !important',
  },
  sectionOne: {
    // backgroundColor: ({ palette }: Theme) => palette.common.bg,
    '&::before': {
      content: '""',
      backgroundImage: `linear-gradient(90deg, transparent, #fff), url(${getAssets(
        'v2/SMAT_06.webp'
      )})`,
      opacity: { xs: 0.2, md: 0.6 },
      backgroundPosition: '0px 0px, 100% 0%',
      backgroundSize: 'auto, cover',
      backgroundRepeat: 'repeat, no-repeat',
      position: 'absolute',
      inset: { xs: '-200px 0 0 -50px', md: '0 0 0 -180px' },
      zIndex: -1,
      transform: 'rotate(180deg)'
    },
    ['@media (max-width:899px)']: {
      marginTop: '4rem'
    },
    ['@media(max-width:599px)']: {
      marginTop: '2rem',
      gap: '1rem'
    }
  },
  thanksHidden: {
    transform: 'translate(150px)',
    maxHeight: 0,
    opacity: 0
  },
  thanksVisible: {
    transition: ({ transitions }: Theme) =>
      transitions.create(['transform', 'opacity'], {
        duration: 2000,
        easing: transitions.easing.easeInOut
      }),
    color: ({ palette }: Theme) => palette.secondary.main,
    fontWeight: 'bold',
    maxHeight: 100,
    opacity: 1,
    transform: 'translate(0px)'
  },
  sectionTwo: {
    padding: '4rem 0rem',
    backgroundColor: 'white'
  },
  sectionThree: {
    '&::before': {
      content: '""',
      backgroundImage: `linear-gradient(90deg, transparent, #fff), url(${getAssets(
        'v2/SMAT_02.webp'
      )})`,
      opacity: { xs: 0.2, md: 0.6 },
      backgroundPosition: '0px 0px, 100% 0%',
      backgroundSize: 'auto, cover',
      backgroundRepeat: 'repeat, no-repeat',
      position: 'absolute',
      inset: '0 0 0 0',
      zIndex: -1,
      transform: 'rotate(180deg)'
    }
  },
  sectionFour: {
    padding: '0rem 0rem 3rem 0rem',
    backgroundColor: 'rgba(216, 223, 233, 0.21)'
  },
  bigTitle: {
    fontSize: '5rem !important',
    ['@media (max-width:899px)']: {
      fontSize: '2rem !important',
      fontWeight: 'bold',
      textAlign: 'center'
    }
  },
  frenchSize: {
    fontSize: {
      xs: '1.5rem !important',
      sm: '2rem !important',
      md: '2.75rem !important'
    }
  },
  title: {
    fontFamily: 'Sora, sans-serif',
    fontWeight: 700,
    fontSize: '3.5rem',
    lineHeight: 1,
    padding: '2rem 2rem',
    letterSpacing: '-1.4px',
    color: ({ palette }: Theme) => palette.common.gradient,
    ['@media (max-width:1239px)']: {
      fontSize: '2.1rem'
    },
    ['@media (max-width:899px)']: {
      fontSize: '2rem',
      fontWeight: 'bold',
      textAlign: 'center',
      padding: '0rem 1rem 1rem'
    }
  },
  content: {
    fontFamily: 'Overpass, sans-serif',
    color: ({ palette }: Theme) => palette.common.text,
    fontWeight: 400,
    padding: '0rem 2rem',
    lineHeight: '176%',
    fontSize: '1.5rem',
    ['@media (max-width:1239px)']: {
      fontSize: '1.5rem'
    },
    ['@media (max-width:899px)']: {
      fontSize: '1rem',
      textAlign: 'justify'
    }
  },
  buyTokenContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '0.5rem'
  },
  roadMapContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    ['@media (max-width:899px)']: {
      // flexDirection: 'column-reverse',
    }
  },
  roadMapImgContainer: {
    display: 'block',
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1,
    backgroundColor: ({ palette }: Theme) => palette.common.white,
    borderRadius: { xs: 5, sm: 10 },
    padding: { xs: '0.5rem 0.5rem 0.5rem 1.5rem', md: '2rem' },
    boxShadow:
      '0 2px 2px 0 rgba(0, 0, 0, .14), 0 1px 5px 0 rgba(0, 0, 0, .12), 0 3px 1px -2px rgba(0, 0, 0, .2)'
  },
  sectionFourContent: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '3rem',
    ['@media (max-width:899px)']: {
      padding: '0.5rem'
    }
  },
  roadMapSideContent: {
    fontSize: '1.5rem',
    lineHeight: 1.6,
    fontFamily: 'Overpass, sans-serif',
    color: ({ palette }: Theme) => palette.common.tableGray,
    fontWeight: 700,
    ['@media (max-width:899px)']: {
      fontSize: '1rem',
      fontWeight: 'regular',
      textAlign: 'justify',
      paddingInline: '2rem'
    }
  },
  roadMap: {
    width: ' 80%',
    marginBottom: '2rem',
    paddingTop: '2rem'
  },
  roadMapImg: {
    maxWidth: '45vw',
    objectFit: 'fill',
    ['@media (max-width:899px)']: {
      maxWidth: '80vw'
    }
  },
  subTitle: {
    fontSize: '2rem',
    fontWeight: 700,
    color: '#292929',
    ['@media (max-width:899px)']: {
      fontSize: '1.5rem',
      paddingBottom: '1rem'
    }
  },
  fourContent: {
    lineHeight: 1.6
  },
  SignUp: {
    transition: ({ transitions }: Theme) =>
      transitions.create('all', {
        duration: 300,
        easing: transitions.easing.easeInOut
      }),
    maxWidth: 'fit-content',
    opacity: 1,
    width: 'fit-content',
    fontSize: '1rem',
    padding: '0.25rem 1.25rem',
    backgroundColor: ({ palette }: Theme) => palette.primary.main,
    color: ({ palette }: Theme) => palette.common.white,
    borderRadius: '5px',
    border: '1px solid transparent',
    fontFamily: 'Sora, sans-serif',
    ':hover': {
      backgroundColor: ({ palette }: Theme) => palette.secondary.main,
      color: ({ palette }: Theme) => palette.common.white,
      border: ({ palette }: Theme) => `1px solid ${palette.secondary.main} `,
      borderRadius: '4px',
      transform: 'scale(0.94)'
    }
  },
  contactUs: {
    width: 'fit-content',
    fontSize: '1.75rem',
    padding: '1rem 4rem',
    backgroundColor: ({ palette }: Theme) => palette.primary.main,
    color: ({ palette }: Theme) => palette.common.white,
    transition: ({ transitions }: Theme) =>
      transitions.create(['all'], {
        duration: transitions.duration.complex,
        easing: transitions.easing.easeInOut
      }),
    borderRadius: '5px',
    border: '1px solid transparent',
    ':hover': {
      backgroundColor: ({ palette }: Theme) => palette.secondary.main,
      border: ({ palette }: Theme) => `1px solid ${palette.secondary.main} `,
      borderRadius: '5px',
      transform: 'scale(0.94)'
    },
    ['@media (max-width:899px)']: {
      fontSize: '1rem',
      padding: '0.25rem 2rem'
    }
  },
  ctaContainer: {
    //   background: `url(${ ctaContainer }) no - repeat`,
    //   backgroundSize: 'cover',
    //   display: 'flex',
    //   justifyContent: 'center',
    //   alignItems: 'center',
    //   width: '80%',
    //   height: 150,
    //   borderRadius: 20,
  },
  oLink: {
    color: ({ palette }: Theme) => palette.primary.main,
    fontSize: 'inherit',
    cursor: 'text',
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 0,
    margin: 0,
    marginBottom: 'auto',
    animation: `${getBounceAnimation} 1s infinite`
  },
  newsletterContainer: {
    paddingInline: '2rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    gap: '0.5rem',
    alignSelf: 'center',
    marginTop: '-2rem'
  },
  oLinkSubtext: {
    maxWidth: '70%',
    textAlign: 'center',
    fontSize: '1rem',
    fontFamily: 'Overpass, sans-serif',
    fontWeight: 600,
    color: ({ palette }: Theme) => palette.common.tableGray,
    ['@media(max-width:599px)']: {
      maxWidth: 'unset',
      textAlign: 'justify'
    }
  },
  popover: {
    margin: '0.25rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '0.2rem'
  },
  iconButton: {
    borderRadius: '5px !important',
    backgroundColor: ({ palette }: Theme) => palette.primary.main,
    color: ({ palette }: Theme) => palette.common.white,
    transition: ({ transitions }: Theme) =>
      transitions.create('all', {
        duration: 300,
        easing: transitions.easing.easeInOut
      }),
    ':hover': {
      backgroundColor: ({ palette }: Theme) => palette.secondary.main
    }
  },
  oSubtitle: {
    fontFamily: 'Overpass, sans-serif',
    color: ({ palette }: Theme) => palette.common.tableGray,
    fontWeight: 700,
    textAlign: 'center',
    backgroundColor: 'transparent'
  },
  collapseHorizontal: {
    transform: 'translate(150px)',
    opacity: 0
  },
  signUpProgress: {
    color: ({ palette }: Theme) => palette.common.white,
    ['@media(max-width:599px)']: {
      ':hover': {
        color: ({ palette }: Theme) => palette.primary.main
      },
      '&:focus': {
        color: ({ palette }: Theme) => palette.primary.main
      }
    }
  },
  moreToCome: {
    fontSize: '2rem !important',
    fontFamily: 'Sora, sans-serif',
    marginTop: '2rem',
    padding: '9px 18px',
    borderRadius: '5px',
    display: 'inline-flex',
    color: '#292929',
    border: `2px solid #292929`
  },
  registerBtn: {
    backgroundColor: ({ palette }: Theme) => palette.primary.main,
    color: ({ palette }: Theme) => palette.common.lightGray,
    minWidth: 'clamp(3rem, 8rem + 5vw, 18rem)',
    justifyContent: 'center',
    padding: ({ spacing }: Theme) => ({
      xs: '0.5rem 1rem',
      sm: spacing(1.5, 4)
    }),
    margin: ({ spacing }: Theme) => spacing(0, 4),
    '& > *': {
      fontFamily: 'Poppins',
      fontWeight: 500,
      fontSize: '1.2rem'
    },
    ':hover': {
      backgroundColor: ({ palette }: Theme) => palette.secondary.main,
      color: ({ palette }: Theme) => palette.common.white
    }
  }
};
export default styles;
